<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Conferma</span>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-container>
          <v-row justify="center">
            <v-col cols="12">
              Sei sicuro di voler clonare tutti gli slot del {{ sourceDate }} al
              {{ date }}. Questa operazione non può essere annullata e
              sovrascrive gli slot alla data destinazione.
            </v-col>
            <v-col cols="12">
              <v-date-picker
                landscape="landscape"
                v-model="date"
                :allowed-dates="filterAllowedDates"
                year-icon="mdi-calendar-blank"
                prev-icon="mdi-skip-previous"
                next-icon="mdi-skip-next"
                first-day-of-week="1"
              ></v-date-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!options.noconfirm"
          color="grey"
          text
          outlined
          class="body-2 ma-2 white--text font-weight-bold"
          @click.native="cancel"
          >Annulla</v-btn
        >
        <v-btn
          color="red"
          class="body-2 ma-2 white--text font-weight-bold"
          @click.native="agree"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "DeleteConfirm",
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      sourceDate: null,
      date: moment().format("YYYY-MM-DD"),
      options: {
        color: "grey lighten-3",
        width: 550,
        zIndex: 200,
        noconfirm: false
      }
    };
  },
  methods: {
    filterAllowedDates(val) {
      return this.sourceDate !== val;
    },
    open(sourceDate, options) {
      this.dialog = true;
      this.sourceDate = sourceDate;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(this.date);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>

<style scoped></style>
