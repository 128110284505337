<template>
  <v-card min-height="100%">
    <custom-loader :loading="loading"></custom-loader>
    <v-card-text>
      <validation-observer ref="observer">
        <form>
          <validation-provider
            v-slot="{ errors }"
            name="Nome Evento"
            rules="required|max:64"
          >
            <v-text-field
              v-model="item.eventName"
              :counter="64"
              :error-messages="errors"
              label="Nome Evento"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Descrizione"
            rules="required|max:255"
          >
            <v-text-field
              v-model="item.description"
              :error-messages="errors"
              :counter="255"
              label="Descrizione"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Script"
            rules="required"
          >
            <v-select
              :items="scripts"
              label="Script"
              :error-messages="errors"
              v-model="item.script.id"
              @input="isDirty = true"
              required
            ></v-select>
          </validation-provider>
          <v-select
            :items="jobRoles"
            label="Ruoli"
            v-model="currentJobRoles"
            multiple
            @input="isDirty = true"
            required
          ></v-select>
          <v-checkbox
            v-model="item.enabled"
            label="Abilitato"
            @change="isDirty = true"
          ></v-checkbox>
        </form>
      </validation-observer>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="mr-4" color="orange" @click="goBackward()">
        Indietro
      </v-btn>
      <v-btn class="mr-4" color="orange" @click="item = { script: {} }">
        Annulla
      </v-btn>
      <v-btn v-if="isDirty" class="mr-4" color="green" @click="submitData()">
        Salva
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import create from "@/mixin/create";
import scripts from "@/mixin/scripts";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend
} from "vee-validate";
import { required, max } from "vee-validate/dist/rules";
import CustomLoader from "@/components/CustomLoader";
import global from "@/mixin/global";
import jobRole from "@/mixin/jobRole";
import eventTypeForm from "@/mixin/eventTypeForm";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto"
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});
export default {
  name: "EventTypeCreate",
  mixins: [create, scripts, global, jobRole, eventTypeForm],
  components: {
    CustomLoader,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      serverPath: "eventType",
      scripts: [],
      item: {
        script: {}
      },
      postSaveRouteName: "EventTypeList"
    };
  },

  computed: {},

  methods: {
    getScripts() {
      this.getScriptsFromApi().then(data => {
        this.scripts = data.items;
      });
    }
  },
  mounted() {
    this.getScripts();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
