<template>
  <v-card min-height="100%">
    <custom-loader :loading="loading"></custom-loader>
    <v-card-text>
      <b>ID Evento</b>
      <div>{{ item.eventId }}</div>
      <b>Comando</b>
      <pre>{{ item.command }}</pre>
      <b>Output Processo</b>
      <pre>{{ item.processOutput }}</pre>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="mr-4" color="orange" @click="goBackward()">
        Indietro
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import edit from "@/mixin/edit";
import scripts from "@/mixin/scripts";
import { setInteractionMode, extend } from "vee-validate";
import { required, max } from "vee-validate/dist/rules";
import CustomLoader from "@/components/CustomLoader";
import global from "@/mixin/global";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto"
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});
export default {
  name: "EventDetailShow",
  mixins: [edit, scripts, global],
  components: {
    CustomLoader
  },
  data() {
    return {
      serverPath: "eventDetail"
    };
  },
  watch: {},
  computed: {},

  methods: {
    setTitle() {
      this.item &&
        this.$emit("set-title", "Dettagli Evento " + this.item.eventId);
    }
  },
  mounted() {
    this.loadData().then(() => {
      // eslint-disable-next-line no-console
      console.log(this.item);
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
