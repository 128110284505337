var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"100%"}},[_c('custom-loader',{attrs:{"loading":_vm.loading}}),_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('form',[_c('validation-provider',{attrs:{"name":"Nome Evento","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":64,"error-messages":errors,"label":"Nome","required":""},on:{"input":function($event){_vm.isDirty = true}},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})]}}])}),_c('validation-provider',{attrs:{"name":"Descrizione","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":255,"label":"Descrizione","required":""},on:{"input":function($event){_vm.isDirty = true}},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})]}}])}),_c('validation-provider',{attrs:{"name":"Comando","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"label":"Comando","required":""},on:{"input":function($event){_vm.isDirty = true}},model:{value:(_vm.item.command),callback:function ($$v) {_vm.$set(_vm.item, "command", $$v)},expression:"item.command"}})]}}])}),_c('validation-provider',{attrs:{"name":"workingDir","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"label":"Directory di Lavoro","required":""},on:{"input":function($event){_vm.isDirty = true}},model:{value:(_vm.item.workingDir),callback:function ($$v) {_vm.$set(_vm.item, "workingDir", $$v)},expression:"item.workingDir"}})]}}])})],1)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"orange"},on:{"click":function($event){return _vm.goBackward()}}},[_vm._v(" Indietro ")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"orange"},on:{"click":function($event){_vm.item = {}}}},[_vm._v(" Annulla ")]),(_vm.isDirty)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"green"},on:{"click":function($event){return _vm.submitData()}}},[_vm._v(" Salva ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }