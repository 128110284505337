<template>
  <v-card min-height="100%">
    <v-dialog v-model="userChannelDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ userChannelDialogTitle }}</span>
        </v-card-title>

        <v-card-text>
          <validation-observer ref="observer">
            <form>
              <validation-provider
                v-slot="{ errors }"
                name="Dettagli Canale"
                rules="required|max:64"
              >
                <v-text-field
                  v-model="editedUserChannel.detail"
                  :counter="64"
                  :error-messages="errors"
                  label="Dettagli Canale"
                  @input="isDirty = true"
                  required
                ></v-text-field>
              </validation-provider>
            </form>
          </validation-observer>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-4" color="orange" @click="closeUserChannelDialog">
            Chiudi
          </v-btn>
          <v-btn
            v-if="isDirty"
            class="mr-4"
            color="green"
            @click="saveUserChannelDialog"
          >
            Salva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon @click="newItem()">mdi-plus-circle</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon @click="loadData()">mdi-refresh</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        item-key="id"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50]
        }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-text-field
            v-model="filters.textFilter"
            label="Nome o Cognome"
            outlined
            class="mx-1"
          ></v-text-field>
          <v-text-field
            v-model="filters.detailFilter"
            label="Dettaglio canale"
            outlined
            class="mx-1"
          ></v-text-field>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </template>
        <template v-slot:[`item.color`]="{ item }">
          <div class="color">
            <div :style="'background-color: ' + item.color + ';'">
              &nbsp;
            </div>
          </div>
        </template>
        <template v-slot:[`item.userChannels`]="{ item }">
          <div v-for="channel in channels" :key="channel.value">
            {{ channel.text }}
            <span
              v-if="getUserChannelDetail(channel.value, item.userChannels).id"
            >
              {{
                getUserChannelDetail(channel.value, item.userChannels).detail
              }}
              <v-icon
                small
                color="green"
                class=""
                @click="editUserChannelDetail(item, channel)"
              >
                mdi-pencil
              </v-icon>
            </span>
            <span v-else>
              <v-icon
                small
                color="red"
                class=""
                @click="createUserChannelDetail(item, channel)"
              >
                mdi-pencil
              </v-icon>
            </span>
          </div>
        </template>
        <template v-slot:[`item.dateCreated`]="{ item }">
          {{ item.dateCreated | formatDateTime }}
        </template>
        <template v-slot:[`item.lastUpdated`]="{ item }">
          {{ item.lastUpdated | formatDateTime }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import table from "@/mixin/table";
import global from "@/mixin/global";

import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import { max, required } from "vee-validate/dist/rules";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto"
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});

export default {
  name: "UserList",
  mixins: [table, global],
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      userChannelDialog: false,
      userChannelDialogTitle: "",
      editedUserChannel: {},
      channels: [],
      isDirty: false,
      headers: [
        {
          text: " ",
          value: "id"
        },
        { text: "Nome", value: "name" },
        { text: "Cognome", value: "surname" },
        { text: "Colore", value: "color" },
        { text: "Canali", value: "userChannels" },
        { text: "Data creazione", value: "dateCreated" },
        { text: "Ultima modifica", value: "lastUpdated" }
      ],
      serverPath: "user"
    };
  },
  methods: {
    editItem(item) {
      this.$router.push({ name: "UserEdit", params: { id: item.id } });
    },
    newItem() {
      this.$router.push({ name: "UserCreate" });
    },
    getUserChannelDetail(current, userChannels) {
      const channel = userChannels.find(function(elm) {
        return elm.channel.id === current;
      });
      if (channel) return channel;

      return {};
    },
    getChannels() {
      this.loading = true;
      this.getChannelsFromApi().then(data => {
        this.channels = data.items;
      });
    },
    getChannelsFromApi() {
      return new Promise(resolve => {
        let items;
        fetch(`${this.serverURL}/channel/combo`, this.configureFetchInit())
          .then(this.handleRestErrors)
          .then(json => {
            items = json ? json : [];

            setTimeout(() => {
              this.loading = false;
              resolve({
                items
              });
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    editUserChannelDetail(userItem, channel) {
      this.userChannelDialog = true;
      this.editedUserChannel = this.getUserChannelDetail(
        channel.value,
        userItem.userChannels
      );
      this.userChannelDialogTitle =
        "Modifica " + channel.text + " per " + userItem.fullName;
    },
    createUserChannelDetail(userItem, channel) {
      this.userChannelDialog = true;
      this.editedUserChannel = {
        channel: channel.value,
        user: userItem.id,
        detail: ""
      };
      this.userChannelDialogTitle =
        "Crea " + channel.text + " per " + userItem.fullName;
    },
    closeUserChannelDialog() {
      this.userChannelDialog = false;
      this.$nextTick(() => {
        this.isDirty = false;
        this.loadData();
        Object.assign(this.editedUserChannel, {});
        // this.editedIndex = -1
      });
    },
    saveUserChannelDialog() {
      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.loading = true;
          this.postEditedUserChannel().then(() => {
            this.isDirty = false;
            this.loading = false;
            this.closeUserChannelDialog();
          });
        }
      });
    },
    buildUrlEditedUserChannel(id) {
      id = id || "";
      return `${this.serverURL}/userChannel/${id}`;
    },
    postEditedUserChannel() {
      return new Promise(resolve => {
        let item;
        fetch(
          this.buildUrlEditedUserChannel(this.editedUserChannel.id),
          this.configureFetchInit({
            method: this.editedUserChannel.id ? "PATCH" : "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(this.editedUserChannel)
          })
        )
          .then(this.handleRestErrors)
          .then(json => {
            item = json.entity;

            setTimeout(() => {
              this.loading = false;
              resolve({
                item
              });
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    }
  },
  mounted() {
    this.loadData();
    this.getChannels();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.color {
  max-height: 18px;
  width: 45px;
  margin: 2px 4px;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  cursor: pointer;
}
.color > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 15px;
}
</style>
