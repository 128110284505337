<template>
  <v-card min-height="100%">
    <v-card-title>Filtri</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <a class="ma-2" @click="lastSeconds(600)">
            Ultimi 10 minuti
          </a>
          <a class="ma-2" @click="lastSeconds(2 * 600)">
            Ultimi 20 minuti
          </a>
          <a class="ma-2" @click="resetTime()">
            Tutti
          </a>
        </v-col>
        <v-col>
          <v-dialog v-model="dateTimeFromDialog" persistent width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ma-2" outlined v-bind="attrs" v-on="on" x-large>
                <span v-if="!dateTimeFrom">Dall'inizio</span>
                <span v-if="dateTimeFrom"
                  >A partire da: {{ dateTimeFrom | formatDateTime }}</span
                >
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-row justify="space-around">
                  <v-col>
                    <v-date-picker
                      width="290"
                      locale="it"
                      :max="maxDateFrom"
                      v-model="dateFrom"
                    ></v-date-picker>
                  </v-col>
                  <v-col>
                    <v-time-picker
                      locale="it"
                      v-model="timeFrom"
                      format="24hr"
                      :max="maxTimeFrom"
                    ></v-time-picker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dateTimeFromDialog = false"
                >
                  Chiudi
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col>
          <v-dialog v-model="dateTimeToDialog" persistent width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ma-2" outlined v-bind="attrs" v-on="on" x-large>
                <span v-if="!dateTimeTo">Fino alla fine</span>
                <span v-if="dateTimeTo"
                  >Fino a: {{ dateTimeTo | formatDateTime }}</span
                >
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-row justify="space-around">
                  <v-col>
                    <v-date-picker
                      locale="it"
                      v-model="dateTo"
                      :max="maxDateTo"
                      :min="minDateTo"
                    ></v-date-picker> </v-col
                  ><v-col>
                    <v-time-picker
                      locale="it"
                      v-model="timeTo"
                      :max="maxTimeTo"
                      :min="minTimeTo"
                      format="24hr"
                    ></v-time-picker>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dateTimeToDialog = false"
                >
                  Chiudi
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="[
              { value: null, text: 'Tutti' },
              { value: 'SUCCESS', text: 'Success' },
              { value: 'FAILED', text: 'Failed' },
              { value: 'NOT_EXECUTED', text: 'Not Executed' }
            ]"
            label="Stato processo"
            outlined
            v-model="filters.processExitValue"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="filters.eventId"
            label="ID Evento"
            outlined
            class="mx-4"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            :items="eventTypes"
            label="Tipo Evento"
            outlined
            v-model="filters.eventType_id"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon @click="loadData()">mdi-refresh</v-icon>
      </v-btn>
    </v-card-actions>
    <v-dialog v-if="retryItem" v-model="retryDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Risultato {{ retryItem.eventId }}</span>
        </v-card-title>
        <v-card-title>
          Script Exit Value
        </v-card-title>
        <v-card-text>
          <v-chip
            :color="getProcessExitValueColor(retryItem.processExitValue)"
            dark
          >
            {{ retryItem.processExitValue }}
          </v-chip>
        </v-card-text>
        <v-card-title>
          Comando
        </v-card-title>
        <v-card-text>
          <pre>{{ retryItem.command }}</pre>
        </v-card-text>
        <v-card-title>
          Directory di Lavoro
        </v-card-title>
        <v-card-text>
          <pre>{{ retryItem.workingDir }}</pre>
        </v-card-text>
        <v-card-title>
          Script Output
        </v-card-title>
        <v-card-text>
          <pre>{{ retryItem.processOutput }}</pre>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeRetryDialog">
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      sort-by="dateCreated"
      sort-desc
      locale="it"
      item-key="id"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50]
      }"
      style="max-width: 100%; padding: 0 15px;"
      class="elevation-1"
    >
      <template v-slot:[`item.id`]="{ item }">
        <v-icon
          v-if="item.processExitValue != 0"
          small
          class="mr-2"
          @click="retryScript(item.id)"
        >
          mdi-autorenew
        </v-icon>
        <v-icon small class="mr-2" @click="showItem(item)">
          mdi-eye
        </v-icon>
      </template>
      <template v-slot:[`item.processExitValue`]="{ item }">
        <v-chip :color="getProcessExitValueColor(item.processExitValue)" dark>
          {{ item.processExitValue }}
        </v-chip>
      </template>
      <template v-slot:[`item.dateCreated`]="{ item }">
        {{ item.dateCreated | formatDateTime }}
      </template>
      <template v-slot:[`item.lastUpdated`]="{ item }">
        {{ item.lastUpdated | formatDateTime }}
      </template>
      <template v-slot:[`item.command`]="{ item }">
        <v-menu absolute offset-y style="max-width: 600px">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{
              item.command | truncate(50, "...")
            }}</span>
          </template>
          <v-card>
            <v-card-text>
              <pre>{{ item.command }}</pre>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:[`item.workingDir`]="{ item }">
        <v-menu absolute offset-y style="max-width: 600px">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{
              item.workingDir | truncate(20, "...", "end")
            }}</span>
          </template>
          <v-card>
            <v-card-text>
              <pre>{{ item.workingDir }}</pre>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:[`item.processOutput`]="{ item }">
        <v-menu absolute offset-y style="max-width: 600px">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{
              item.processOutput | truncate(50, "...")
            }}</span>
          </template>
          <v-card>
            <v-card-text>
              <pre>{{ item.processOutput }}</pre>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:[`item.eventType`]="{ item }">
        {{ getEventTypeNameFromId(item.eventType.id) }}
        <v-icon
          small
          class="mr-2"
          @click="editEventTypeItem(item.eventType.id)"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import table from "@/mixin/table";
import datetime from "@/mixin/datetime";
import global from "@/mixin/global";

export default {
  name: "EventDetailList",
  mixins: [table, datetime, global],
  data() {
    return {
      menuDateFrom: false,
      retryItem: null,
      retryDialog: false,
      eventTypes: [],
      filters: {},
      headers: [
        {
          text: " ",
          value: "id"
        },
        { text: "ID Evento", value: "eventId" },
        { text: "Tipo Evento", value: "eventType" },
        { text: "Comando", value: "command" },
        { text: "Script Exit Value", value: "processExitValue" },
        { text: "Script Output", value: "processOutput" },
        { text: "Data Esecuzione", value: "dateCreated" },
        { text: "Directory di Lavoro", value: "workingDir" }
      ],
      serverPath: "eventDetail"
    };
  },
  methods: {
    showItem(item) {
      this.$router.push({ name: "EventDetailShow", params: { id: item.id } });
    },
    getEventTypeNameFromId(id) {
      const et = this.eventTypes.find(function(e) {
        return e.value == id;
      });

      return et?.text || "";
    },
    getProcessExitValueColor(exitValue) {
      if (exitValue == 0) return "green";
      else return "red";
    },
    editEventTypeItem(oid) {
      this.$router.push({ name: "EventTypeEdit", params: { id: oid } });
    },
    closeRetryDialog() {
      this.retryDialog = false;
      this.$nextTick(() => {
        this.loadData();
      });
    },
    getEventTypes() {
      this.loading = true;
      this.getEventTypesFromApi().then(data => {
        this.eventTypes = [{ text: "Tutti", value: null }].concat(data.items);
      });
    },
    getEventTypesFromApi() {
      return new Promise(resolve => {
        let items;
        fetch(`${this.serverURL}/eventType/combo`, this.configureFetchInit())
          .then(this.handleRestErrors)
          .then(json => {
            items = json ? json : [];

            setTimeout(() => {
              this.loading = false;
              resolve({
                items
              });
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    retryScript(id) {
      this.loading = true;
      this.retryScriptFromApi(id).then(data => {
        this.retryItem = data.item;
        this.retryDialog = true;
      });
    },
    retryScriptFromApi(id) {
      return new Promise(resolve => {
        let item;
        fetch(
          `${this.serverURL}/${this.serverPath}/retry/${id}`,
          this.configureFetchInit()
        )
          .then(this.handleRestErrors)
          .then(json => {
            item = json.entity ? json.entity : [];

            setTimeout(() => {
              this.loading = false;
              resolve({
                item
              });
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    }
  },
  mounted() {
    this.loadData();
    this.getEventTypes();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
