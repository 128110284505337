<template>
  <v-app id="app">
    <!-- -->
    <v-snackbar v-model="error.show" multi-line color="red">
      <v-icon large>
        mdi-alert-octagon
      </v-icon>
      {{ error.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          v-bind="attrs"
          color="white"
          @click="error.show = false"
        >
          <v-icon large color="red">
            mdi-window-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Messenger
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(menuLink, i) in menuLinks"
            :key="i"
            :to="menuLink.link"
          >
            <v-list-item-content>
              <v-list-item-title v-text="menuLink.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!-- -->
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click.stop="goBackward()">
        <v-icon>mdi-step-backward</v-icon>
      </v-btn>
      <v-btn icon @click.stop="goForward()">
        <v-icon>mdi-step-forward</v-icon>
      </v-btn>
      <v-btn icon @click="keycloak.logout()">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- If using vue-router -->
      <!-- Provides the application the proper gutter -->
      <router-view
        @show-error="showError"
        @set-title="setTitle"
        :keycloak="keycloak"
      ></router-view>
    </v-main>

    <v-footer app padless>
      <v-row justify="center" no-gutters>
        <v-col class="text-right text-body-2" cols="12">
          bever:<strong> {{ app.info.be.version }} </strong> fever:<strong>
            {{ app.info.fe.version }}
          </strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import global from "@/mixin/global";

export default {
  name: "app",
  mixins: [global],
  components: {},
  data() {
    return {
      title: "",
      error: { message: null, show: false },
      drawer: null,
      menuLinks: [
        { text: "Eventi Ricevuti", link: "/event-detail" },
        { text: "Tipo Eventi", link: "/event-type" },
        { text: "Script", link: "/script-detail" },
        { text: "Ruoli", link: "/job-role" },
        { text: "Utenti", link: "/user" },
        { text: "Canali", link: "/channel" },
        { text: "Festività", link: "/holidays" }
      ]
    };
  },
  computed: {},
  watch: {
    $route(to) {
      this.title = to.meta.title || "";
    }
  },
  methods: {
    showError(obj) {
      obj.show = true;
      this.error = obj;
    },
    setTitle(title) {
      this.title = title;
    },
    loadInfo() {
      fetch(
        `${this.serverURL}/application/version`,
        this.configureFetchInit({
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        })
      )
        .then(this.handleRestErrors)
        .then(json => {
          this.app.info.be = json;
        })
        .catch(this.handleErrors);
    }
  },
  mounted() {
    this.setTitle(this.$route.meta.title);
    this.loadInfo();
  }
};
</script>
