<template>
  <v-card min-height="100%">
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon @click="newItem()">mdi-plus-circle</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon @click="loadData()">mdi-refresh</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        item-key="id"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50]
        }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-text-field
            v-model="filters.textFilter"
            label="Nome o Descrizione"
            outlined
            class="mx-1"
          ></v-text-field>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </template>
        <template v-slot:[`item.dateCreated`]="{ item }">
          {{ item.dateCreated | formatDateTime }}
        </template>
        <template v-slot:[`item.enabled`]="{ item }">
          <v-chip :color="item.enabled ? 'green' : 'red'" dark>
            <v-icon small>
              {{ item.enabled ? "mdi-thumb-up" : "mdi-thumb-down" }}
            </v-icon>
          </v-chip>
        </template>
        <template v-slot:[`item.lastUpdated`]="{ item }">
          {{ item.lastUpdated | formatDateTime }}
        </template>
        <template v-slot:[`item.script`]="{ item }">
          {{ getScriptNameFromId(item.script.id) }}
          <v-icon
            small
            class="mr-2"
            @click="editScriptDetailItem(item.script.id)"
          >
            mdi-pencil
          </v-icon>
        </template>
        <template v-slot:[`item.jobRoles`]="{ item }">
          <div v-for="jobRole in item.jobRoles" :key="jobRole.id">
            {{ getJobRoleNameFromId(jobRole.id) }}
            <v-icon small class="mr-2" @click="editJobRoleItem(jobRole.id)">
              mdi-pencil
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import table from "@/mixin/table";
import global from "@/mixin/global";
import scripts from "@/mixin/scripts";
import jobRole from "@/mixin/jobRole";

export default {
  name: "EventTypeList",
  mixins: [table, global, scripts, jobRole],
  data() {
    return {
      headers: [
        {
          text: " ",
          value: "id"
        },
        { text: "Nome", value: "eventName" },
        { text: "Descrizione", value: "description" },
        { text: "Attivo", value: "enabled" },
        { text: "Script", value: "script" },
        { text: "Ruoli", value: "jobRoles" },
        { text: "Data creazione", value: "dateCreated" },
        { text: "Ultima modifica", value: "lastUpdated" }
      ],
      serverPath: "eventType"
    };
  },
  methods: {
    editItem(item) {
      this.$router.push({ name: "EventTypeEdit", params: { id: item.id } });
    },
    newItem() {
      this.$router.push({ name: "EventTypeCreate" });
    },
    editScriptDetailItem(oid) {
      this.$router.push({ name: "ScriptDetailEdit", params: { id: oid } });
    },
    editJobRoleItem(oid) {
      this.$router.push({ name: "JobRoleEdit", params: { id: oid } });
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
