var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"100%"}},[_c('custom-loader',{attrs:{"loading":_vm.loading}}),_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('form',[_c('validation-provider',{attrs:{"name":"Nome","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.item && _vm.item.hasOwnProperty('name'))?_c('v-text-field',{attrs:{"counter":64,"error-messages":errors,"label":"Nome","required":""},on:{"input":function($event){_vm.isDirty = true}},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}):_vm._e()]}}])}),_c('validation-provider',{attrs:{"name":"Descrizione","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.item && _vm.item.hasOwnProperty('description'))?_c('v-text-field',{attrs:{"error-messages":errors,"counter":255,"label":"Descrizione","required":""},on:{"input":function($event){_vm.isDirty = true}},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}}):_vm._e()]}}])}),(_vm.item && _vm.item.hasOwnProperty('date'))?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data","prepend-icon":"mdi-calendar","readonly":"","error-messages":errors,"required":""},on:{"input":function($event){_vm.isDirty = true}},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,1420157975),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{on:{"input":function($event){_vm.isDirty = true;
              _vm.datePicker = false;}},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})],1):_vm._e(),_c('v-checkbox',{attrs:{"label":"Abilitato"},on:{"change":function($event){_vm.isDirty = true}},model:{value:(_vm.item.enabled),callback:function ($$v) {_vm.$set(_vm.item, "enabled", $$v)},expression:"item.enabled"}})],1)])],1),_c('v-card-actions',[(!_vm.isDirty)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteConfirm()}}},[_vm._v(" Cancella ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"orange"},on:{"click":function($event){return _vm.goBackward()}}},[_vm._v(" Indietro ")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"orange"},on:{"click":function($event){return _vm.loadData()}}},[_vm._v(" Annulla ")]),(_vm.isDirty)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"green"},on:{"click":function($event){return _vm.submitData()}}},[_vm._v(" Salva ")]):_vm._e()],1),_c('delete-confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }