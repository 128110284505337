<template>
  <v-dialog
    v-model="loadingDialog"
    content-class="loader-dialog"
    elevation="0"
    width="70"
    persistent
  >
    <v-card color="white" dark elevation="0">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        class="p-5"
        indeterminate
      ></v-progress-circular>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CustomLoader",
  props: {
    loading: Boolean
  },
  computed: {
    loadingDialog: {
      // getter
      get() {
        return this.loading;
      },
      // setter
      set(val) {
        this.$emit("loader-state-change", val);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
