<template>
  <v-card min-height="100%">
    <custom-loader :loading="loading"></custom-loader>
    <v-card-text>
      <validation-observer ref="observer">
        <form>
          <validation-provider
            v-slot="{ errors }"
            name="Nome"
            rules="required|max:64"
          >
            <v-text-field
              v-model="item.name"
              :counter="64"
              :error-messages="errors"
              label="Nome"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Cognome"
            rules="required|max:255"
          >
            <v-text-field
              v-model="item.surname"
              :error-messages="errors"
              :counter="255"
              label="Cognome"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Colore"
            rules="required|max:255"
          >
            <v-color-picker
              v-if="item"
              v-model="item.color"
              :error-messages="errors"
              @input="isDirty = true"
              hide-inputs
              hide-sliders
              hide-canvas
              hide-mode-switch
              class="ma-2"
              required
              show-swatches
            ></v-color-picker>
          </validation-provider>
        </form>
      </validation-observer>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="mr-4" color="orange" @click="goBackward()">
        Indietro
      </v-btn>
      <v-btn class="mr-4" color="orange" @click="item = {}">
        Annulla
      </v-btn>
      <v-btn v-if="isDirty" class="mr-4" color="green" @click="submitData()">
        Salva
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import create from "@/mixin/create";
import global from "@/mixin/global";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend
} from "vee-validate";
import { required, max } from "vee-validate/dist/rules";
import CustomLoader from "@/components/CustomLoader";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto"
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});

export default {
  name: "UserCreate",
  mixins: [create, global],
  components: {
    CustomLoader,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      serverPath: "User",
      item: {},
      postSaveRouteName: "UserList"
    };
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
