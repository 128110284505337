<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-stepper v-model="e1" alt-labels>
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1" editable>
            Seleziona data
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :complete="e1 > 2"
            step="2"
            :editable="model.date != null"
          >
            Orario inizio
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :complete="e1 > 3"
            step="3"
            :editable="model.start != null"
          >
            Orario fine
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="4" :editable="endValid()">
            Utente
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card>
              <v-card-text>
                <v-date-picker
                  landscape="landscape"
                  v-model="model.date"
                  full-width
                  year-icon="mdi-calendar-blank"
                  prev-icon="mdi-skip-previous"
                  next-icon="mdi-skip-next"
                  first-day-of-week="1"
                ></v-date-picker>
              </v-card-text>
              <v-card-actions>
                <v-btn v-if="!options.noconfirm" text @click.native="cancel">
                  Annulla
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="model.date" color="primary" @click="e1++">
                  Continua
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card>
              <v-card-text>
                <v-time-picker
                  format="24hr"
                  full-width
                  v-model="model.start"
                  :allowed-minutes="allowedStep"
                ></v-time-picker>
              </v-card-text>
              <v-card-actions>
                <v-btn v-if="!options.noconfirm" text @click.native="cancel">
                  Annulla
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="e1--">
                  Indietro
                </v-btn>
                <v-btn v-if="model.start" color="primary" @click="e1++">
                  Continua
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card>
              <v-card-text>
                <v-time-picker
                  format="24hr"
                  full-width
                  v-model="model.end"
                  :min="model.start"
                  :allowed-minutes="allowedStep"
                ></v-time-picker>
                <v-btn @click="model.end = '23:59:59'">fine giornata</v-btn>
              </v-card-text>
              <v-card-actions>
                <v-btn v-if="!options.noconfirm" text @click.native="cancel">
                  Annulla
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="e1--">
                  Indietro
                </v-btn>
                <v-btn color="primary" @click="e1++" v-if="endValid()">
                  Continua
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-card>
              <v-card-text>
                <v-autocomplete
                  v-model="model.user"
                  :items="users"
                  :filter="filterUser"
                  color="white"
                  item-text="text"
                  item-value="value"
                  label="Utente"
                ></v-autocomplete>
              </v-card-text>
              <v-card-actions>
                <v-btn v-if="!options.noconfirm" text @click.native="cancel">
                  Annulla
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="e1--">
                  Indietro
                </v-btn>
                <v-btn
                  color="red"
                  class="body-2 ma-2 white--text font-weight-bold"
                  @click.native="agree"
                  v-if="model.user"
                  >Salva</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";
import global from "@/mixin/global";
export default {
  name: "AddFlowConfirm",
  mixins: [global],
  data() {
    return {
      e1: 1,
      dialog: false,
      resolve: null,
      reject: null,
      users: [],
      totalUsers: 0,
      allowedStep: m => m % 5 === 0 || m === 59,
      model: {
        date: null,
        start: null,
        end: null,
        user: null
      },
      options: {
        color: "grey lighten-3",
        width: 700,
        zIndex: 200,
        noconfirm: false
      }
    };
  },
  methods: {
    open(model, options) {
      this.e1 = 1;
      this.dialog = true;
      this.model = Object.assign(this.model, model);
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    endValid() {
      return (
        this.model.start &&
        this.model.end &&
        moment.duration(this.model.end) > moment.duration(this.model.start)
      );
    },
    agree() {
      this.resolve(this.model);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    loadUserCombo() {
      this.loading = true;
      this.getUserComboFromApi().then(data => {
        this.users = data;
      });
    },
    buildUserComboUrl() {
      return `${this.serverURL}/user/combo`;
    },
    getUserComboFromApi() {
      return new Promise(resolve => {
        let items;
        fetch(this.buildUserComboUrl(), this.configureFetchInit())
          .then(this.handleRestErrors)
          .then(json => {
            items = json ? json : [];

            setTimeout(() => {
              this.loading = false;
              resolve(items);
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    filterUser(item, queryText) {
      return item.text.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    }
  },
  mounted() {
    this.loadUserCombo();
  }
};
</script>

<style scoped></style>
