<template>
  <v-card min-height="100%">
    <custom-loader :loading="loading"></custom-loader>
    <dlg-wrapper ref="dlg">
      <user-job-role-range-dialog
        :keycloak="keycloak"
      ></user-job-role-range-dialog>
    </dlg-wrapper>

    <v-card-text>
      <v-sheet tile height="54" class="d-flex">
        <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon class="ma-2" @click="reload()">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu
          v-model="selectDateDialog"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="value"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="value"
            @input="selectDateDialog = false"
            @update:picker-date="changeDatePickerRage"
            :events="datePickerEvents"
          ></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn icon class="ma-2" @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-sheet>
      <v-calendar
        ref="calendar"
        v-model="value"
        color="primary"
        type="week"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        :events="items"
        :event-color="getEventColor"
        :event-ripple="false"
        @change="getEvents"
        @mousedown:event="startDrag"
        @mousedown:time="startTime"
        @mousemove:time="mouseMove"
        @mouseup:time="endDrag"
        @contextmenu:time="contextMenu"
      >
        <template v-slot:day-header="{ date, present, past }">
          <div
            class="v-calendar-daily_head-weekday"
            :class="{ 'primary--text': present }"
          >
            {{ nameOfMonthAndYear(date) }}
          </div>
          <v-container class="pt-0 pb-1">
            <v-row no-gutters>
              <v-btn
                icon
                x-small
                :color="
                  present
                    ? 'primary'
                    : past
                    ? 'secondary lighten-4'
                    : 'secondary'
                "
                @click="deleteDayConfirm(date)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                icon
                x-small
                :color="
                  present
                    ? 'primary'
                    : past
                    ? 'secondary lighten-4'
                    : 'secondary'
                "
                @click="addOrUpdateDayConfirm(date)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                icon
                x-small
                :color="
                  present
                    ? 'primary'
                    : past
                    ? 'secondary lighten-4'
                    : 'secondary'
                "
                @click="cloneDayConfirm(date)"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </template>
        <template v-slot:event="{ event, timed }">
          <div class="v-event-draggable">
            <v-btn size="15" icon dense @click="showEvent(event, $event)">
              <v-icon color="white" dense size="15">mdi-pencil</v-icon>
            </v-btn>
            <strong @click="showEvent(event, $event)">{{ event.name }}</strong>
            <br />
            <div>{{ event.start | formatDateTimeNoSecond }}</div>
            <div>{{ event.fend | formatDateTimeNoSecond }}</div>
            <span v-if="event.user">
              <div
                v-for="userChannel in event.user.userChannels"
                :key="userChannel.id"
              >
                {{ userChannel.channel.code }} - {{ userChannel.detail }}
              </div>
            </span>
          </div>
          <div
            v-if="timed"
            class="v-event-drag-bottom"
            @mousedown.stop="extendBottom(event)"
          ></div>
        </template>
      </v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        min-width="500"
        max-width="700"
        offset-x
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-app-bar :color="selectedEvent.color" dark>
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                selectedToBeEdited = false;
                selectedToBeDeleted = !selectedToBeDeleted;
              "
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="
                selectedToBeDeleted = false;
                selectedToBeEdited = !selectedToBeEdited;
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="closeEvent()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-app-bar>
          <v-container>
            <v-row dense v-if="!selectedToBeEdited">
              <v-col cols="12">
                <v-card>
                  <v-card-title class="text-h6">
                    {{ selectedEvent.start | formatDateTimeNoSecond }}
                    -
                    {{ selectedEvent.fend | formatDateTimeNoSecond }}
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
            <v-card-text v-if="selectedToBeDeleted" class="text-center">
              <v-btn color="red" @click="deleteSelectedItem()"
                >conferma cancellazione</v-btn
              >
            </v-card-text>
            <v-stepper v-model="e1" alt-labels v-if="selectedToBeEdited">
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1" editable>
                  Seleziona data
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="e1 > 2"
                  step="2"
                  :editable="selectedEvent.fromDate != null"
                >
                  Orario inizio
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="e1 > 3"
                  step="3"
                  :editable="selectedEvent.fromTime != null"
                >
                  Orario fine
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="4" :editable="endValid()">
                  Utente
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card>
                    <v-card-text>
                      <v-date-picker
                        landscape="landscape"
                        v-model="selectedEvent.fromDate"
                        full-width
                        year-icon="mdi-calendar-blank"
                        prev-icon="mdi-skip-previous"
                        next-icon="mdi-skip-next"
                        first-day-of-week="1"
                      ></v-date-picker>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="selectedEvent.fromDate"
                        color="primary"
                        @click="e1++"
                      >
                        Continua
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card>
                    <v-card-text>
                      <v-time-picker
                        format="24hr"
                        full-width
                        v-model="selectedEvent.fromTime"
                        :allowed-minutes="allowedStep"
                      ></v-time-picker>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" @click="e1--">
                        Indietro
                      </v-btn>
                      <v-btn
                        v-if="selectedEvent.fromTime"
                        color="primary"
                        @click="e1++"
                      >
                        Continua
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-card>
                    <v-card-text>
                      <v-time-picker
                        format="24hr"
                        full-width
                        v-model="selectedEvent.toTime"
                        :min="selectedEvent.fromTime"
                        :allowed-minutes="allowedStep"
                      ></v-time-picker>
                      <v-btn @click="selectedEvent.toTime = '23:59:59'"
                        >fine giornata</v-btn
                      >
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" @click="e1--">
                        Indietro
                      </v-btn>
                      <v-btn color="primary" @click="e1++" v-if="endValid()">
                        Continua
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <v-card>
                    <v-card-text>
                      <v-autocomplete
                        v-model="selectedEvent.user.id"
                        :items="users"
                        :filter="filterUser"
                        color="white"
                        item-text="text"
                        item-value="value"
                        label="Utente"
                      ></v-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" @click="e1--">
                        Indietro
                      </v-btn>
                      <v-btn
                        color="red"
                        class="body-2 ma-2 white--text font-weight-bold"
                        @click.native="updateDayConfirm()"
                        v-if="selectedEvent.user"
                        >Salva</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-container>
        </v-card>
      </v-menu>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="mr-4" color="orange" @click="goBackward()">
        Indietro
      </v-btn>
      <v-btn class="mr-4" color="orange" @click="loadData()">
        Annulla
      </v-btn>
    </v-card-actions>
    <delete-confirm ref="confirm"></delete-confirm>
    <clone-confirm ref="clone"></clone-confirm>
    <add-flow-confirm ref="add" :keycloak="keycloak"></add-flow-confirm>
  </v-card>
</template>

<script>
import edit from "@/mixin/edit";
import CustomLoader from "@/components/CustomLoader";
import global from "@/mixin/global";

import moment from "moment-timezone";
import UserJobRoleRangeDialog from "@/components/jobRole/components/UserJobRoleRangeDialog";
import DlgWrapper from "@/components/DlgWrapper";
import DeleteConfirm from "@/components/DeleteConfirm";
import CloneConfirm from "./components/CloneConfirm";
import AddFlowConfirm from "./components/AddFlowConfirm";

export default {
  name: "JobRoleSlotEdit",
  mixins: [edit, global],
  components: {
    DeleteConfirm,
    AddFlowConfirm,
    CloneConfirm,
    CustomLoader,
    DlgWrapper,
    UserJobRoleRangeDialog
  },
  data() {
    return {
      e1: 1,
      allowedStep: m => m % 5 === 0 || m === 59,
      selectedEvent: {},
      selectedElement: null,
      selectedToBeEdited: false,
      selectedToBeDeleted: false,
      selectedOpen: false,
      datePickerEvents: [],
      selectDateDialog: false,
      endDayTolerance: 1,
      items: [],
      users: [],
      usersMap: new Map(),
      total: 0,
      serverPath: "jobRole",
      filters: {
        jobRole: this.$route.params.id
      },
      value: moment().format("YYYY-MM-DD"),
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
        "red",
        "pink"
      ]
    };
  },
  watch: {
    selectDateDialog() {
      this.changeDatePickerRage(this.value);
    }
  },
  computed: {},
  methods: {
    updateDayConfirm() {
      this.loading = true;

      const start = moment(
        `${this.selectedEvent.fromDate} ${this.selectedEvent.fromTime}`
      );
      let end = moment(
        `${this.selectedEvent.fromDate} ${this.selectedEvent.toTime}`
      );
      if (end.format("ss") == "00") {
        end = end.subtract(this.endDayTolerance, "milliseconds");
      }

      const ujrr = {
        id: this.selectedEvent.id,
        jobRole: this.filters.jobRole,
        user: this.selectedEvent.user?.id,
        fromTime: start.format("YYYY-MM-DDTHH:mm:ss.SSS"),
        toTime: end.format("YYYY-MM-DDTHH:mm:ss.SSS")
      };
      this.postUserJobRoleRange(ujrr).then(() => {
        this.closeEvent();
        this.reload();
      });
    },
    async addOrUpdateDayConfirm(date) {
      const model = await this.$refs.add.open({ date });
      if (model !== false) {
        const start = moment(`${model.date} ${model.start}`);
        let end = moment(`${model.date} ${model.end}`);
        if (end.format("ss") == "00") {
          end = end.subtract(this.endDayTolerance, "milliseconds");
        }
        const ujrr = {
          user: model.user,
          jobRole: this.filters.jobRole,
          fromTime: start.format("YYYY-MM-DDTHH:mm:ss.SSS"),
          toTime: end.format("YYYY-MM-DDTHH:mm:ss.SSS")
        };
        this.postUserJobRoleRange(ujrr).then(() => {
          this.reload();
        });
      }
    },
    async deleteDayConfirm(date) {
      if (
        await this.$refs.confirm.open(
          "Conferma",
          "Sei sicuro di voler cancellare tutti gli slot di questo giorno?"
        )
      ) {
        this.deleteDay(date);
      }
    },
    async cloneDayConfirm(date) {
      const clone = await this.$refs.clone.open(date);
      if (clone !== false) {
        this.cloneDay(date, clone);
      }
    },
    cloneDay(fromDate, toDate) {
      this.loading = true;
      this.cloneDayFromApi(fromDate, toDate).then(() => {
        this.reload();
      });
    },
    deleteDay(date) {
      this.loading = true;
      this.deleteDayFromApi(date).then(() => {
        this.reload();
      });
    },
    buildUrlUserJobRoleCloneDay(fromDay, toDay) {
      let _this = this;
      let url = `${this.serverURL}/userJobRoleRange/cloneDay?fromDay=${fromDay}&toDay=${toDay}&jobRole=${_this.filters.jobRole}`;
      return url;
    },
    buildUrlUserJobRoleDeleteDay(date) {
      let _this = this;
      let url = `${this.serverURL}/userJobRoleRange/deleteDay?day=${date}&jobRole=${_this.filters.jobRole}`;
      return url;
    },
    cloneDayFromApi(fromDay, toDay) {
      return new Promise(resolve => {
        fetch(
          this.buildUrlUserJobRoleCloneDay(fromDay, toDay),
          this.configureFetchInit({
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            }
          })
        )
          .then(this.handleDeleteRestErrors)
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              resolve({});
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    deleteDayFromApi(date) {
      return new Promise(resolve => {
        fetch(
          this.buildUrlUserJobRoleDeleteDay(date),
          this.configureFetchInit({
            method: "DELETE",
            headers: {
              "Content-Type": "application/json"
            }
          })
        )
          .then(this.handleDeleteRestErrors)
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              resolve({});
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    reload() {
      this.loadUserJobRoleRange();
    },
    closeEvent() {
      this.selectedEvent.user.id = this.selectedOriginalUser;
      this.selectedOpen = false;
    },
    showEvent(event, nativeEvent) {
      const open = () => {
        this.selectedToBeEdited = false;
        this.selectedToBeDeleted = false;
        this.selectedEvent = event;
        this.selectedOriginalUser = event.user?.id;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.closeEvent();
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    changeDatePickerRage(month) {
      let _this = this;
      this.loading = true;
      this.getUserJobRoleCountFromApi(month).then(data => {
        this.datePickerEvents = [];
        data.forEach(function(elm) {
          _this.datePickerEvents.push(elm.date);
        });
      });
    },
    getUserJobRoleCountFromApi(month) {
      return new Promise(resolve => {
        let items;
        fetch(this.buildUrlUserJobRoleCount(month), this.configureFetchInit())
          .then(this.handleRestErrors)
          .then(json => {
            items = json ? json : [];

            setTimeout(() => {
              this.loading = false;
              resolve(items);
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    buildUrlUserJobRoleCount(month) {
      let _this = this;
      return `${this.serverURL}/userJobRoleRange/date?month=${month}&jobRole=${_this.filters.jobRole}`;
    },
    setTitle() {
      this.item && this.$emit("set-title", "Modifica ruolo " + this.item.code);
    },
    buildUrlUserJobRole(itemsPerPage, page, sortBy, sortDesc) {
      let _this = this;
      let url = this.buildPaginationUrl(
        `${this.serverURL}/userJobRoleRange/query`,
        itemsPerPage,
        page,
        sortBy,
        sortDesc
      );
      if (this.filters) {
        Object.keys(this.filters).forEach(function(key) {
          if (_this.filters[key]) {
            url += `&${key.replace("_", ".")}=${_this.filters[key]}`;
          }
        });
      }
      return url;
    },
    getEvents({ start, end }) {
      Object.assign(this.filters, {
        fromTime: this.formatLocalDateTime(start.date, "00:00:00"),
        toTime: this.formatLocalDateTime(end.date, "23:59:59")
      });
      this.loadUserJobRoleRange();
    },
    getUserDetail(id) {
      return new Promise(resolve => {
        let user = this.usersMap.get(id);
        if (!user) {
          user = this.getUserDetailFromApi(id);
          this.usersMap.set(id, user);
        }
        resolve(user);
      });
    },
    getUserDetailFromApi(id) {
      return new Promise(resolve => {
        let user;
        fetch(`${this.serverURL}/user/${id}`, this.configureFetchInit())
          .then(this.handleRestErrors)
          .then(json => {
            user = json.entity ? json.entity : {};
            setTimeout(() => {
              this.loading = false;
              resolve(user);
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    loadUserJobRoleRange() {
      this.loading = true;
      this.getUserJobRoleRangeFromApi().then(data => {
        this.parseItems(data.items).then(pdata => {
          this.items = pdata;
        });
        this.total = data.total;
      });
    },
    parseItem(item) {
      return new Promise(resolve => {
        this.getUserDetail(item.user?.id).then(user => {
          const start = moment(String(item.fromTime));
          let end = moment(String(item.toTime));
          if (end.format("HHmm") == "0000") {
            end = end.subtract(this.endDayTolerance, "milliseconds");
          }
          const fend = moment(end).add(this.endDayTolerance, "milliseconds");

          resolve({
            id: item.id,
            user,
            name: user.fullName,
            color: this.resolveColor(user.color),
            fromDate: item.fromDay,
            toDate: item.toDay,
            fromTime: start.format("HH:mm"),
            toTime: fend.format("HH:mm"),
            start: start.toDate(),
            end: end.toDate(),
            fend,
            timed: true
          });
        });
      });
    },
    parseItems(items) {
      this.loading = true;
      return new Promise(resolve => {
        let tmpii = [];
        items.forEach(item => {
          this.parseItem(item).then(tmpi => {
            tmpii.push(tmpi);
          });
        });
        this.loading = false;
        resolve(tmpii);
      });
    },
    getUserJobRoleRangeFromApi() {
      return new Promise(resolve => {
        let items, total;
        fetch(
          this.buildUrlUserJobRole(100, 1, "fromTime", [false]),
          this.configureFetchInit()
        )
          .then(this.handleRestErrors)
          .then(json => {
            items = json.entities ? json.entities : [];
            total =
              json.paging && json.paging.totalCount
                ? json.paging.totalCount
                : 0;

            setTimeout(() => {
              this.loading = false;
              resolve({
                items,
                total
              });
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    buildUserJobRoleRange(id) {
      id = id || "";
      return `${this.serverURL}/userJobRoleRange/${id}`;
    },
    deleteUserJobRoleRange(userJobRoleRangeId) {
      return new Promise(resolve => {
        fetch(
          this.buildUserJobRoleRange(userJobRoleRangeId),
          this.configureFetchInit({
            method: "DELETE",
            headers: {
              "Content-Type": "application/json"
            }
          })
        )
          .then(this.handleDeleteRestErrors)
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              resolve(true);
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    postUserJobRoleRange(userJobRoleRange) {
      let _this = this;
      _this.loading = true;
      return new Promise(resolve => {
        let item;
        let tbpUserJobRoleRange = {
          id: userJobRoleRange.id,
          fromTime: userJobRoleRange.fromTime,
          toTime: userJobRoleRange.toTime,
          user: {
            id:
              typeof userJobRoleRange.user == "number"
                ? userJobRoleRange.user
                : userJobRoleRange.user.id
          }
        };

        if (typeof userJobRoleRange.jobRole !== "undefined") {
          tbpUserJobRoleRange.jobRole = userJobRoleRange.jobRole;
        }

        fetch(
          this.buildUserJobRoleRange(tbpUserJobRoleRange.id),
          this.configureFetchInit({
            method: tbpUserJobRoleRange.id ? "PATCH" : "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(tbpUserJobRoleRange)
          })
        )
          .then(this.handleRestErrors)
          .then(json => {
            item = json.entity;

            setTimeout(() => {
              this.loading = false;
              this.reload();

              resolve(item);
            }, this.timeout);
          })
          .catch(this.handleErrors)
          .catch(() => {
            this.reload();
          });
      });
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.originalEnd = event.end;
        this.originalStart = event.start;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
      } else {
        const cStart = this.roundTime(mouse, true);
        const cEnd = this.roundTime(mouse, false);
        this.createEvent = {
          name: `Seleziona un utente.... (${this.items.length})`,
          color: "grey",
          start: cStart,
          end: cEnd,
          timed: true
        };
        this.items.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.dragEvent = event;
      this.createEvent = event;
      this.originalEnd = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        let newEnd = newStart + duration;
        if (moment(newEnd).format("HHmmss") === "000000") {
          newEnd = newEnd - this.endDayTolerance;
        }
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent) {
        const mouseRoundedDown = this.roundTime(mouse, false);
        const mouseRoundedUp =
          this.roundTime(mouse, false) - this.endDayTolerance;
        const min = Math.min(mouseRoundedDown, this.createEvent.start);
        const max = Math.max(mouseRoundedUp, this.createEvent.start);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    isOverlap(start, end) {
      return new Promise((resolve, reject) => {
        let ii = 0;
        let overlap = false;
        while (this.items[ii] && !overlap) {
          const item = this.items[ii];
          const sisie = moment(start).isBetween(item.start, item.end);
          const eisie = moment(end).isBetween(item.start, item.end);
          const isse = moment(item.start).isBetween(start, end);
          const iese = moment(item.end).isBetween(start, end);

          const eq =
            moment(item.end).isSame(end) &&
            moment(item.start).isSame(start) &&
            typeof item.start != "number";

          overlap = overlap || sisie || eisie || isse || iese || eq;

          ii++;
        }
        if (overlap) reject(overlap);

        resolve(overlap);
      });
    },
    deleteSelectedItem() {
      let _this = this;
      if (this.selectedEvent) {
        this.deleteUserJobRoleRange(this.selectedEvent.id).then(() => {
          let fi = this.items.find(function(e) {
            return e.id == _this.selectedEvent.id;
          });
          const i = this.items.indexOf(fi);
          if (i !== -1) {
            this.items.splice(i, 1);
          }
          this.closeEvent();
        });
      }
    },
    endDrag() {
      if (this.dragEvent) {
        this.isOverlap(
          this.dragEvent.start,
          this.dragEvent.end,
          this.dragEvent.id
        )
          .then(() => {
            const ujrr = {
              id: this.dragEvent.id,
              user: this.dragEvent.user,
              fromTime: moment(this.dragEvent.start).format(
                "YYYY-MM-DDTHH:mm:ss.SSS"
              ),
              toTime: moment(this.dragEvent.end).format(
                "YYYY-MM-DDTHH:mm:ss.SSS"
              )
            };
            this.postUserJobRoleRange(ujrr).then(() => {
              this.dragTime = null;
              this.dragEvent = null;
              this.createEvent = null;
              this.originalEnd = null;
            });
          })
          .catch(() => {
            this.cancelDrag();
          });
      } else if (this.createEvent) {
        this.isOverlap(this.createEvent.start, this.createEvent.end)
          .then(() => {
            this.$refs.dlg.open().then(userid => {
              if (userid) {
                const ujrr = {
                  user: userid,
                  jobRole: this.filters.jobRole,
                  fromTime: moment(this.createEvent.start).format(
                    "YYYY-MM-DDTHH:mm:ss.SSS"
                  ),
                  toTime: moment(this.createEvent.end).format(
                    "YYYY-MM-DDTHH:mm:ss.SSS"
                  )
                };
                this.postUserJobRoleRange(ujrr).then(() => {
                  this.reload();
                  this.dragTime = null;
                  this.dragEvent = null;
                  this.createEvent = null;
                  this.originalEnd = null;
                });
              }
              const i = this.items.indexOf(this.createEvent);
              if (i !== -1) {
                this.items.splice(i, 1);
              }
            });
          })
          .catch(() => {
            const i = this.items.indexOf(this.createEvent);
            if (i !== -1) {
              this.items.splice(i, 1);
            }
            this.loading = false;
          });
      }
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.originalEnd) {
          this.createEvent.end = this.originalEnd;
          this.createEvent = null;
        } else {
          // const i = this.items.indexOf(this.createEvent)
          // if (i !== -1) {
          //  this.items.splice(i, 1)
          // }
        }
        this.reload();
      } else if (this.dragEvent) {
        this.dragEvent.start = this.originalStart;
        this.dragEvent.end = this.originalEnd;
        this.dragEvent = null;
        this.dragTime = null;
      }
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color;
    },
    resolveColor(color) {
      if (!color || typeof color == "undefined") return "grey";

      return color;
    },
    loadUsersData() {
      this.loading = true;
      this.getUsersDataFromApi().then(data => {
        this.users = data;
      });
    },
    buildUrlUsers() {
      return `${this.serverURL}/user/combo`;
    },
    getUsersDataFromApi() {
      return new Promise(resolve => {
        let items;
        fetch(this.buildUrlUsers(), this.configureFetchInit())
          .then(this.handleRestErrors)
          .then(json => {
            items = json ? json : [];

            setTimeout(() => {
              this.loading = false;
              resolve(items);
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    filterUser(item, queryText) {
      return item.text.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    },
    endValid() {
      return (
        this.selectedEvent.fromTime &&
        this.selectedEvent.toTime &&
        moment.duration(this.selectedEvent.toTime) >
          moment.duration(this.selectedEvent.fromTime)
      );
    },
    contextMenu() {}
  },
  mounted() {
    this.loadData();
    this.loadUsersData();
    this.loadUserJobRoleRange();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
