<template>
  <v-card min-height="100%">
    <custom-loader :loading="loading"></custom-loader>
    <v-card-text>
      <validation-observer ref="observer">
        <form>
          <validation-provider
            v-slot="{ errors }"
            name="Nome"
            rules="required|max:64"
          >
            <v-text-field
              v-if="item && item.hasOwnProperty('name')"
              v-model="item.name"
              :counter="64"
              :error-messages="errors"
              label="Nome"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Cognome"
            rules="required|max:255"
          >
            <v-text-field
              v-if="item && item.hasOwnProperty('surname')"
              v-model="item.surname"
              :error-messages="errors"
              :counter="255"
              label="Cognome"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Colore"
            rules="required|max:255"
          >
            <v-color-picker
              v-if="item && item.hasOwnProperty('color')"
              v-model="item.color"
              :error-messages="errors"
              @input="isDirty = true"
              hide-inputs
              hide-sliders
              hide-canvas
              hide-mode-switch
              class="ma-2"
              required
              show-swatches
            ></v-color-picker>
          </validation-provider>
        </form>
      </validation-observer>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="!isDirty"
        class="mr-4"
        color="red"
        @click="deleteUserJobRoleRangeDataConfirm()"
      >
        Svuota calendario associato
      </v-btn>
      <v-btn v-if="!isDirty" class="mr-4" color="red" @click="deleteConfirm()">
        Cancella
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="mr-4" color="orange" @click="goBackward()">
        Indietro
      </v-btn>
      <v-btn class="mr-4" color="orange" @click="loadData()">
        Annulla
      </v-btn>
      <v-btn v-if="isDirty" class="mr-4" color="green" @click="submitData()">
        Salva
      </v-btn>
    </v-card-actions>
    <delete-confirm ref="confirm" />
  </v-card>
</template>

<script>
import edit from "@/mixin/edit";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend
} from "vee-validate";
import { required, max } from "vee-validate/dist/rules";
import CustomLoader from "@/components/CustomLoader";
import DeleteConfirm from "@/components/DeleteConfirm";
import global from "@/mixin/global";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto"
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});
export default {
  name: "UserEdit",
  mixins: [edit, global],
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomLoader,
    DeleteConfirm
  },
  data() {
    return {
      serverPath: "user"
    };
  },

  computed: {},

  methods: {
    setTitle() {
      this.item &&
        this.$emit("set-title", "Modifica utente " + this.item.fullName);
    },
    async deleteUserJobRoleRangeDataConfirm() {
      if (
        await this.$refs.confirm.open(
          "Conferma",
          "Sei sicuro di voler cancellare questo record?"
        )
      ) {
        this.deleteUserJobRoleRangeData();
      }
    },
    deleteUserJobRoleRangeData() {
      this.loading = true;
      this.deleteUserJobRoleRangeDataFromApi(
        this.$route.params.id
      ).then(() => {});
    },
    buildDeleteUserJobRoleRangeDataUrl(id) {
      return `${this.serverURL}/${this.serverPath}/deleteUserJobRoleRange/${id}`;
    },
    deleteUserJobRoleRangeDataFromApi(id) {
      return new Promise(resolve => {
        fetch(
          this.buildDeleteUserJobRoleRangeDataUrl(id),
          this.configureFetchInit({
            method: "DELETE",
            headers: {
              "Content-Type": "application/json"
            }
          })
        )
          .then(this.handleDeleteRestErrors)
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              resolve({});
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
