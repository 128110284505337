<template>
  <v-card min-height="100%">
    <custom-loader :loading="loading"></custom-loader>
    <v-card-text>
      <validation-observer ref="observer">
        <form>
          <validation-provider
            v-slot="{ errors }"
            name="Nome Evento"
            rules="required|max:64"
          >
            <v-text-field
              v-if="item && item.hasOwnProperty('eventName')"
              v-model="item.eventName"
              :counter="64"
              :error-messages="errors"
              label="Nome Evento"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Descrizione"
            rules="required|max:255"
          >
            <v-text-field
              v-if="item && item.hasOwnProperty('description')"
              v-model="item.description"
              :error-messages="errors"
              :counter="255"
              label="Descrizione"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>

          <v-select
            v-if="item && item.hasOwnProperty('script')"
            :items="scripts"
            label="Script"
            v-model="item.script.id"
            @input="isDirty = true"
            required
          ></v-select>
          <v-select
            v-if="item && item.hasOwnProperty('jobRoles')"
            :items="jobRoles"
            label="Ruoli"
            v-model="currentJobRoles"
            multiple
            @input="isDirty = true"
            required
          ></v-select>

          <v-checkbox
            v-if="item && item.hasOwnProperty('enabled')"
            v-model="item.enabled"
            label="Abilitato"
            @change="isDirty = true"
          ></v-checkbox>
        </form>
      </validation-observer>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="mr-4" color="orange" @click="goBackward()">
        Indietro
      </v-btn>
      <v-btn class="mr-4" color="orange" @click="loadData()">
        Annulla
      </v-btn>
      <v-btn v-if="isDirty" class="mr-4" color="green" @click="submitData()">
        Salva
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import edit from "@/mixin/edit";
import scripts from "@/mixin/scripts";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend
} from "vee-validate";
import { required, max } from "vee-validate/dist/rules";
import CustomLoader from "@/components/CustomLoader";
import global from "@/mixin/global";
import jobRole from "@/mixin/jobRole";
import eventTypeForm from "@/mixin/eventTypeForm";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto"
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});
export default {
  name: "EventTypeEdit",
  mixins: [edit, scripts, global, jobRole, eventTypeForm],
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomLoader
  },
  data() {
    return {
      serverPath: "eventType"
    };
  },
  watch: {},
  computed: {},

  methods: {
    setTitle() {
      this.item &&
        this.$emit("set-title", "Modifica Evento " + this.item.eventName);
    }
  },
  mounted() {
    this.loadData().then(() => {
      this.mapData();
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
