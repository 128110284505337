var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"100%"}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.userChannelDialog),callback:function ($$v) {_vm.userChannelDialog=$$v},expression:"userChannelDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.userChannelDialogTitle))])]),_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('form',[_c('validation-provider',{attrs:{"name":"Dettagli Canale","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":64,"error-messages":errors,"label":"Dettagli Canale","required":""},on:{"input":function($event){_vm.isDirty = true}},model:{value:(_vm.editedUserChannel.detail),callback:function ($$v) {_vm.$set(_vm.editedUserChannel, "detail", $$v)},expression:"editedUserChannel.detail"}})]}}])})],1)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"orange"},on:{"click":_vm.closeUserChannelDialog}},[_vm._v(" Chiudi ")]),(_vm.isDirty)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"green"},on:{"click":_vm.saveUserChannelDialog}},[_vm._v(" Salva ")]):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.newItem()}}},[_vm._v("mdi-plus-circle")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.loadData()}}},[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"item-key":"id","footer-props":{
        itemsPerPageOptions: [10, 25, 50]
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-1",attrs:{"label":"Nome o Cognome","outlined":""},model:{value:(_vm.filters.textFilter),callback:function ($$v) {_vm.$set(_vm.filters, "textFilter", $$v)},expression:"filters.textFilter"}}),_c('v-text-field',{staticClass:"mx-1",attrs:{"label":"Dettaglio canale","outlined":""},model:{value:(_vm.filters.detailFilter),callback:function ($$v) {_vm.$set(_vm.filters, "detailFilter", $$v)},expression:"filters.detailFilter"}})]},proxy:true},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.color",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"color"},[_c('div',{style:('background-color: ' + item.color + ';')})])]}},{key:"item.userChannels",fn:function(ref){
      var item = ref.item;
return _vm._l((_vm.channels),function(channel){return _c('div',{key:channel.value},[_vm._v(" "+_vm._s(channel.text)+" "),(_vm.getUserChannelDetail(channel.value, item.userChannels).id)?_c('span',[_vm._v(" "+_vm._s(_vm.getUserChannelDetail(channel.value, item.userChannels).detail)+" "),_c('v-icon',{attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.editUserChannelDetail(item, channel)}}},[_vm._v(" mdi-pencil ")])],1):_c('span',[_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.createUserChannelDetail(item, channel)}}},[_vm._v(" mdi-pencil ")])],1)])})}},{key:"item.dateCreated",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.dateCreated))+" ")]}},{key:"item.lastUpdated",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.lastUpdated))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }