<template>
  <v-card color="grey lighten-4" min-width="350px" flat class="overflow-hidden">
    <v-toolbar dark color="blue lighten-1">
      <v-toolbar-title>
        Seleziona utente
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="cancel">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-autocomplete
        v-model="model"
        :items="items"
        :filter="filterUser"
        color="white"
        item-text="text"
        item-value="value"
        label="Utente"
      ></v-autocomplete>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn white color="green darken-1" @click="agree(model)">Salva </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import global from "@/mixin/global";

export default {
  name: "UserJobRoleRangeDialog",
  mixins: [global],
  props: {},
  data: () => ({
    model: null,
    items: [],
    total: 0,
    serverPath: "user"
  }),
  inject: ["agree", "cancel"],
  methods: {
    loadData() {
      this.loading = true;
      this.getDataFromApi().then(data => {
        this.items = data;
      });
    },
    buildUrl() {
      return `${this.serverURL}/${this.serverPath}/combo`;
    },
    getDataFromApi() {
      return new Promise(resolve => {
        let items;
        fetch(this.buildUrl(), this.configureFetchInit())
          .then(this.handleRestErrors)
          .then(json => {
            items = json ? json : [];

            setTimeout(() => {
              this.loading = false;
              resolve(items);
            }, this.timeout);
          })
          .catch(this.handleErrors);
      });
    },
    filterUser(item, queryText) {
      return item.text.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    }
  },
  computed: {},
  mounted() {
    this.loadData();
  }
};
</script>
