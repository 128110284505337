<template>
  <v-card min-height="100%">
    <custom-loader :loading="loading"></custom-loader>
    <v-card-text>
      <validation-observer ref="observer">
        <form>
          <validation-provider
            v-slot="{ errors }"
            name="Codice"
            rules="required|max:64"
          >
            <v-text-field
              v-if="item && item.hasOwnProperty('code')"
              v-model="item.code"
              :counter="64"
              :error-messages="errors"
              label="Nome"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Descrizione"
            rules="required|max:255"
          >
            <v-text-field
              v-if="item && item.hasOwnProperty('description')"
              v-model="item.description"
              :error-messages="errors"
              :counter="255"
              label="Descrizione"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
        </form>
      </validation-observer>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="mr-4" color="orange" @click="goBackward()">
        Indietro
      </v-btn>
      <v-btn class="mr-4" color="orange" @click="loadData()">
        Annulla
      </v-btn>
      <v-btn v-if="isDirty" class="mr-4" color="green" @click="submitData()">
        Salva
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import edit from "@/mixin/edit";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend
} from "vee-validate";
import { required, max } from "vee-validate/dist/rules";
import CustomLoader from "@/components/CustomLoader";
import global from "@/mixin/global";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto"
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});
export default {
  name: "ChannelEdit",
  mixins: [edit, global],
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomLoader
  },
  data() {
    return {
      serverPath: "Channel"
    };
  },

  computed: {},

  methods: {
    setTitle() {
      this.item && this.$emit("set-title", "Modifica canale " + this.item.code);
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
