var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"100%"}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.newItem()}}},[_vm._v("mdi-plus-circle")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.loadData()}}},[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"item-key":"id","footer-props":{
        itemsPerPageOptions: [10, 25, 50]
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-1",attrs:{"label":"Nome o Descrizione","outlined":""},model:{value:(_vm.filters.textFilter),callback:function ($$v) {_vm.$set(_vm.filters, "textFilter", $$v)},expression:"filters.textFilter"}})]},proxy:true},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showRange(item)}}},[_vm._v(" mdi-calendar-range ")])]}},{key:"item.dateCreated",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.dateCreated))+" ")]}},{key:"item.lastUpdated",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.lastUpdated))+" ")]}},{key:"item.defaultUser",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDefaultUserNameFromId(item.defaultUser.id))+" "),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDefaultUserItem(item.defaultUser.id)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }