<template>
  <v-card min-height="100%">
    <custom-loader :loading="loading"></custom-loader>
    <v-card-text>
      <validation-observer ref="observer">
        <form>
          <validation-provider
            v-slot="{ errors }"
            name="Nome"
            rules="required|max:64"
          >
            <v-text-field
              v-if="item && item.hasOwnProperty('name')"
              v-model="item.name"
              :counter="64"
              :error-messages="errors"
              label="Nome"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Descrizione"
            rules="required|max:255"
          >
            <v-text-field
              v-if="item && item.hasOwnProperty('description')"
              v-model="item.description"
              :error-messages="errors"
              :counter="255"
              label="Descrizione"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>

          <v-menu
            v-if="item && item.hasOwnProperty('date')"
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors }"
                name="Data"
                rules="required"
              >
                <v-text-field
                  v-model="item.date"
                  label="Data"
                  prepend-icon="mdi-calendar"
                  readonly
                  :error-messages="errors"
                  @input="isDirty = true"
                  required
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </validation-provider>
            </template>
            <v-date-picker
              v-model="item.date"
              @input="
                isDirty = true;
                datePicker = false;
              "
            ></v-date-picker>
          </v-menu>
          <v-checkbox
            v-model="item.enabled"
            label="Abilitato"
            @change="isDirty = true"
          ></v-checkbox>
        </form>
      </validation-observer>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="!isDirty" class="mr-4" color="red" @click="deleteConfirm()">
        Cancella
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="mr-4" color="orange" @click="goBackward()">
        Indietro
      </v-btn>
      <v-btn class="mr-4" color="orange" @click="loadData()">
        Annulla
      </v-btn>
      <v-btn v-if="isDirty" class="mr-4" color="green" @click="submitData()">
        Salva
      </v-btn>
    </v-card-actions>
    <delete-confirm ref="confirm" />
  </v-card>
</template>

<script>
import edit from "@/mixin/edit";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend
} from "vee-validate";
import { required, max } from "vee-validate/dist/rules";
import CustomLoader from "@/components/CustomLoader";
import global from "@/mixin/global";
import DeleteConfirm from "@/components/DeleteConfirm";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto"
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});
export default {
  name: "HolidaysEdit",
  mixins: [edit, global],
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomLoader,
    DeleteConfirm
  },
  data() {
    return {
      serverPath: "holidays",
      datePicker: false
    };
  },

  computed: {},

  methods: {
    setTitle() {
      this.item &&
        this.$emit("set-title", "Modifica festività " + this.item.name);
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
