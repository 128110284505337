<template>
  <v-card min-height="100%">
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon @click="newItem()">mdi-plus-circle</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon @click="loadData()">mdi-refresh</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        item-key="id"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50]
        }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-text-field
            v-model="filters.textFilter"
            label="Nome o Descrizione"
            outlined
            class="mx-1"
          ></v-text-field>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </template>
        <template v-slot:[`item.dateCreated`]="{ item }">
          {{ item.dateCreated | formatDateTime }}
        </template>
        <template v-slot:[`item.lastUpdated`]="{ item }">
          {{ item.lastUpdated | formatDateTime }}
        </template>
        <template v-slot:[`item.command`]="{ item }">
          <v-menu absolute offset-y style="max-width: 600px">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{
                item.command | truncate(50, "...", "end")
              }}</span>
            </template>
            <v-card>
              <v-card-text>
                <pre>{{ item.command }}</pre>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
        <template v-slot:[`item.workingDir`]="{ item }">
          <v-menu absolute offset-y style="max-width: 600px">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{
                item.workingDir | truncate(20, "...", "end")
              }}</span>
            </template>
            <v-card>
              <v-card-text>
                <pre>{{ item.workingDir }}</pre>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import table from "@/mixin/table";
import global from "@/mixin/global";

export default {
  name: "ScriptDetailist",
  mixins: [table, global],
  data() {
    return {
      headers: [
        {
          text: " ",
          value: "id"
        },
        { text: "Nome", value: "name" },
        { text: "Descrizione", value: "description" },
        { text: "Comando", value: "command" },
        { text: "Directory di Lavoro", value: "workingDir" },
        { text: "Data creazione", value: "dateCreated" },
        { text: "Ultima modifica", value: "lastUpdated" }
      ],
      serverPath: "ScriptDetail"
    };
  },
  methods: {
    editItem(item) {
      this.$router.push({ name: "ScriptDetailEdit", params: { id: item.id } });
    },
    newItem() {
      this.$router.push({ name: "ScriptDetailCreate" });
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
